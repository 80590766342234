<template>
  <div>
    <loader message="Confirming payment ...">
      <img width="120px" class="mb-10" src="@/assets/paystack.png" />
    </loader>
  </div>
</template>
<script>
import Loader from '@/components/cards/Loader'
export default {
  name: 'ConfirmPayment',
  components: {
    loader: Loader,
  },
  props: {
    method: {
      type: String,
      required: false,
    },
    trxref: {
      type: String,
      required: false,
    },
    reference: {
      type: String,
      required: false,
    },
  },
  created() {
    window.opener.oauthCallback({
      method: this.method,
      trxref: this.trxref,
      reference: this.reference,
    })
  },
}
</script>
